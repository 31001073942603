
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "dashboard",
  components: {},

  setup() {
    const store = useStore();
    const userRole = computed(() => store.getters["UserModule/getUserRole"]);

    return { userRole };
  },
});
